import React from "react"

export const PageContext = React.createContext()

const PageContextProvider = ({pageContext, location, children}) => {
  return (
    <PageContext.Provider
      value={{
        location,
        pageContext
      }}
    >
      {children}
    </PageContext.Provider>
  )
}
export default PageContext
export { PageContextProvider }
