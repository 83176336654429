import { createGlobalStyle } from "styled-components"

export const Theme = {
  color1: "#CDAA8C",
  color2: "#003236",
  color3: "#1E1922",
  color4: "#E9C9AE",
  color5: "#003236",
  color6: "white",
  color7: "black",
  fontSize1: "18px",
  fontSize2: "20px",
  fontSize3: "0.875rem",
}

export const GlobalStyle = createGlobalStyle``
