import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { PageContextProvider } from "./src/providers/page_context"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, Theme } from "./src/themes/abyadina"
import "./src/styles/bulma.scss"
import "./src/styles/global.scss"
import "./src/styles/slick-carousel.scss"
import { Toaster } from "react-hot-toast"

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider theme={Theme}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_V3_SITE_KEY}>
      <GlobalStyle />
      <PageContextProvider {...props}>{element}</PageContextProvider>
      <Toaster />
    </GoogleReCaptchaProvider>
  </ThemeProvider>
)
